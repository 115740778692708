import { getAsyncFetch } from "../../utils/fetcher";

const ORGANIZATIONS = 'organizations';
//const CATEGORIES = 'categories';
const WORDING = 'wording';
const AUTH = 'auth';
const CHAT = 'chats';

export default class APIService {

    getOrganizations = async (language) => {
      return await getAsyncFetch(`${ORGANIZATIONS}${language ? ('?language='+language) : ''}`);
    }

    /*
    getCategories = async () => {
        return await getAsyncFetch(`${CATEGORIES}`);
    }

     */

    getWording = async () => {
        return await getAsyncFetch(`${WORDING}`);
    }

    requestPin = async (email) => {
        return await getAsyncFetch(`${ORGANIZATIONS}/email/pin`, 'POST', { email });
    }

    validatePin = async (email, pin) => {
        return await getAsyncFetch(`${ORGANIZATIONS}/email/validate`, 'POST', { email, pin });
    }

    getCategories = async () => {
        return await getAsyncFetch(`${ORGANIZATIONS}/categories`, 'GET');
    }

    updateOrganizationDetails = async (id, email, pin, organization) => {
        return await getAsyncFetch(`${ORGANIZATIONS}/${id}`, 'PUT', { email, pin, organization });
    }

    getLinkByEmail = async (email) => {
        return await getAsyncFetch(`${ORGANIZATIONS}/hide?email=${email}`);
    }

    hideOrganizationByEmail = async (email, token) => {
        return await getAsyncFetch(`${ORGANIZATIONS}/hide/confirm?email=${email}&token=${token}`);
    }

    // user

    loginUserByEmail = async (email, secondary) => {
        return await getAsyncFetch(`${AUTH}/email/login?partner=bymapka${secondary ? '&secondary=on' : ''}`, 'POST', { email }, null, true);
    };

    validateUserPin = async (email, pin) => {
        return await getAsyncFetch(`${AUTH}/email/validate`, 'POST', { email, pin }, null, true);
    };

    // chat

    getChatAnonymous = async (id) => {
        return await getAsyncFetch(`${CHAT}/map/${id}`, 'GET', null, null, true);
    };

    getChat = async ( id ) => {
        return await getAsyncFetch(`${CHAT}/${id}`, 'GET', null, true, true);
    };

    recordChatInteraction = async ( id, type ) => {
        return await getAsyncFetch(`${CHAT}/interaction/${id}`, 'POST', { type: 'type-business' }, true, true);
    };

    recordChatMessage = async ( id, type, text ) => {
        return await getAsyncFetch(`${CHAT}/message/${id}`, 'POST', { type: 'type-business', text }, true, true);
    };
}
