import React, {useEffect, useState} from "react";
import InputLabeled from "../ui/controls/input-labeled";
import CheckBox from "../ui/controls/checkbox";
import {connect} from "react-redux";

const AccountDetails = ({ data, email, onUpdate, categories, language, wording  }) =>  {

    const [localData, setLocalData] = useState(data)
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        setLocalData(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(data)]);

    if (!data || !Array.isArray(data)) return <div className='p-20'>
        <p>Произошла ошибка при загрузке данных</p>
    </div>


    const updateFieldHandler = (field, value) => {
        const copy = [...localData]
        copy[currentIndex] = {
            ...copy[currentIndex],
            [field]: value
        }
        setLocalData(copy)
    }

    const organizations = localData.map(el => el.Name) || []

    let organization = localData[currentIndex]
    if (!organization) return <div className='p-20'>
        <p>{wording['account-screen-error'][language]}</p>
    </div>

    const {
        id,
        Published=false,
        Name='',
        Address='',
        Lat='',
        Lng='',
        Phone='',
        Services='',
        WorkTime='',
        Discount='',
        DiscountDescription='',
        Website='',
        SocialNetworks='',
        Category,
        Country

    } = organization

    const updateDataHandler = () => {
        onUpdate && onUpdate(id, organization)
    }

    const categoryId = Array.isArray(Category) ? Category[0] : Category;
    const category = categories.find(el => el.id === categoryId);
    const selectCategories = categories.map(el => {
        return {
            label: el.Name,
            value: el.id
        }
    })

    const categoryValue = category?.id || ''

    return (
        <div className='mxw-400'>
            <h3>{email}</h3>
            <div className='mt-20'>
                <p className='mb-5'>{wording['account-screen-org'][language]}</p>
                <select className='wp-100' value={currentIndex} onChange={e => setCurrentIndex(e.target.value)}>
                    {organizations.map((item, index) => <option key={index} value={index}>{item}</option>)}
                </select>
            </div>
            <div className='separator mt-20 mb-20' />
            <div className='mt-20'>
                <CheckBox value={Published} onChange={(val) => updateFieldHandler('Published', val)} label={wording['account-screen-publish'][language]} />
            </div>
            <div className='mt-30'>
                <InputLabeled label={wording['account-screen-name'][language]} className={'wp-100'} value={Name} onChange={(val) => updateFieldHandler('Name', val)} />
            </div>
            <div className='mt-30'>
                <InputLabeled label={wording['account-screen-country'][language]} className={'wp-100'} value={Country} onChange={(val) => updateFieldHandler('Country', val)} />
                <InputLabeled label={wording['account-screen-address'][language]} className={'wp-100 mt-20'} value={Address} onChange={(val) => updateFieldHandler('Address', val)} />
                <div className='d-flex-wrap mt-20' style={{gap: 10}}>
                    <InputLabeled label={wording['account-screen-address-lat'][language]} placeholder={'52.22677527262351'} className={'w-150'} value={Lat} onChange={(val) => updateFieldHandler('Lat', val)} />
                    <InputLabeled label={wording['account-screen-address-lng'][language]} placeholder={'20.99969672632955'} className={'w-150'} value={Lng} onChange={(val) => updateFieldHandler('Lng', val)} />
                </div>
            </div>
            <div className='mt-30'>
                <select value={categoryValue} onChange={e => updateFieldHandler('Category', [e.target.value])}>
                    {selectCategories.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                </select>
            </div>
            <div className='mt-30'>
                <InputLabeled textarea label={wording['account-screen-services'][language]} className={'wp-100'} value={Services} onChange={(val) => updateFieldHandler('Services', val)} />
            </div>
            <div className='mt-30'>
                <InputLabeled placeholder={'10%'} label={wording['account-screen-discount'][language]} className={'wp-100'} value={Discount} onChange={(val) => updateFieldHandler('Discount', val)} />
                <InputLabeled placeholder={'для ўсіх беларусаў'} textarea containerClassName={'mt-20'} label={wording['account-screen-discount-note'][language]} className={'wp-100'} value={DiscountDescription} onChange={(val) => updateFieldHandler('DiscountDescription', val)} />

            </div>
            <div className='mt-30'>
                <InputLabeled label={wording['account-screen-phone'][language]} className={'wp-100'} value={Phone} onChange={(val) => updateFieldHandler('Phone', val)} />
                <InputLabeled textarea containerClassName={'mt-20'} label={wording['account-screen-worktime'][language]} className={'wp-100'} value={WorkTime} onChange={(val) => updateFieldHandler('WorkTime', val)} />
            </div>
            <div className='mt-30'>
                <InputLabeled label={wording['account-screen-website'][language]} className={'wp-100'} value={Website} onChange={(val) => updateFieldHandler('Website', val)} />
                <InputLabeled textarea containerClassName={'mt-20'} label={wording['account-screen-social'][language]} className={'wp-100'} value={SocialNetworks} onChange={(val) => updateFieldHandler('SocialNetworks', val)} />
            </div>
            <div className='mt-30'>
                <button className='button-main' onClick={() => updateDataHandler()}>{wording['account-screen-button'][language]}</button>
            </div>
        </div>
    );
}


const mapStateToProps = ({ language: { language, wording }}) => {
    return { language, wording };
};


export default connect(mapStateToProps)(AccountDetails);
