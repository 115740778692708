import React, {useEffect, useState} from "react";
import Linkify from 'react-linkify';
import {classifySocialNetwork} from "../../utils/social";
import {AiFillFacebook, AiFillInstagram, AiFillTwitterCircle, AiOutlineLink} from "react-icons/ai";
import {FACEBOOK, INSTAGRAM, TWITTER, LINK} from "../../utils/social";
import ReactPlayer from "react-player";
import {connect} from "react-redux";
import copy from 'copy-to-clipboard';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LoginScreenEmail from "../login-screen-email";
import APIService from "../../services/api-service";
import HBox from "../ui/layouts/hbox";
import {FiChevronRight, FiCopy, FiHeart, FiMessageCircle, FiX} from "react-icons/fi";
import VBox from "../ui/layouts/vbox";
import {dateToFullStringDate} from "../../utils/dates";

const apiService = new APIService()

const ICO_SIZE = 20;

const getIco = (type) => {
    switch (type) {
        case FACEBOOK: return <AiFillFacebook className='fc-url' size={ICO_SIZE} />;
        case INSTAGRAM: return <AiFillInstagram className='fc-url' size={ICO_SIZE} />;
        case TWITTER: return <AiFillTwitterCircle className='fc-url' size={ICO_SIZE} />;
        case LINK: return <AiOutlineLink className='fc-url' size={ICO_SIZE} />;
        default: return <AiOutlineLink className='fc-url' size={ICO_SIZE} />;
    }
}

let holdingLike = false

const Organization = props => {

    const { token, item: {id, Name, Discount, Description, DiscountDescription, Category, CategoryBy, CategoryEn, CategoryRu, Image, VideoUrl, Services, Address, Phone, Email, WorkTime, SocialNetworks, Website}, language, wording } = props;

    const [chatInstance, setChatInstance] = useState({})
    const [comment, setComment] = useState('')
    const [commentMode, setCommentMode] = useState(false)

    const copyToClipboard = () => {
        copy(`${process.env.REACT_APP_WEBSITE}?id=${id}`);
        window.alert('Link copied!')
    }


    const addCommentHandler  = async () => {
        if (comment && token && id) {
            try {
                const result = await apiService.recordChatMessage(id, 'type-business', comment)
                if (result) {
                    setChatInstance(result)
                    setComment('')
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    const addInteractionHandler  = async () => {
        if (token && id) {
            try {
                const result = await apiService.recordChatInteraction(id, 'type-business')
                if (result) {
                    setChatInstance(result)
                }
            } catch (e) {
                console.log(e)
            }
        } else {
            holdingLike = true
            setCommentMode(true)
        }
    }

    const socialNetworksBlock = [];
    if (SocialNetworks) {
        const snString = SocialNetworks.split('\n\n').join('\n');
        const snArray = snString.split('\n');
        snArray.forEach(item => {
             if (item.trim()) {
                 socialNetworksBlock.push(classifySocialNetwork(item.trim()))
             }
        })
    }

    const categoryName = {
        en: CategoryEn,
        ru: CategoryRu,
        ua: Category,
        by: CategoryBy

    }

    const getChat = async (chatId, token) => {
        try {
            const result = !token
                ? await apiService.getChatAnonymous(chatId)
                : await apiService.getChat(chatId)
            if (result) {
                setChatInstance(result)
            }
            if (holdingLike) {
                holdingLike = false
                addInteractionHandler()
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (id) {
            getChat(id, token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, id])

    const { likesCount=0, messages=[], messagesCount=0, isInteraction=false } = chatInstance

    return (
        <div>
            <Linkify>
            <p className='fs-10 mb-5'>{categoryName[language]}</p>
            <h3>{Name}</h3>
            <div className='separator mt-10' />
            {Image &&
                <div className='mt-20 d-flex'>
                    {Image.length > 1 &&
                       <Carousel showThumbs={false} showIndicators={false}>
                           {Image.map((item, index) => <div key={index}>
                               <img src={item.url} width='100%' height={200} style={{objectFit: 'cover'}} alt='organization'/>
                           </div>)}
                       </Carousel>}
                    {Image.length === 1 &&
                        <img src={Image[0].url} width='100%' alt='organization'/>
                    }
                </div>}
            {VideoUrl && <div className='mt-20'><ReactPlayer width="100%"
                                      controls={true} playing={false} url={VideoUrl}/></div>}
            {Services &&
            <div className='mt-20'>
                <p className='strong'>{wording['card_services'][language]}</p>
                <p className='mt-5 pre-line'>{Services}</p>
            </div>}
            {Description &&
                <div className='mt-20'>
                    <p className='strong'>{wording['card_services'][language]}</p>
                    <p className='mt-5 pre-line'>{Description}</p>
                </div>}
            {(Discount || DiscountDescription) &&
                <div className='mt-20 bg-gray p-15'>
                    {Discount && <p className='strong'>{`${wording['discount-heading']?.[language] || 'Зніжкі для беларусаў: '} ${Discount}`}%</p>}
                    {DiscountDescription && <p className='mt-5 pre-line'>{DiscountDescription}</p>}
                </div>}
            {Website &&
                <div className='mt-20'>
                    <p className='pre-line'>{Website}</p>
                </div>
            }
            {WorkTime &&
            <div className='mt-20'>
                <p className='strong'>{wording['card_time'][language]}</p>
                <p className='mt-5 pre-line'>{WorkTime}</p>
            </div>}
            {Address &&
            <a href={`https://maps.google.com?q=${props.item.lat},${props.item.lng}`} target={'_blank'} rel="noopener noreferrer"><div className='mt-20 cli'>
                <p className='strong '>{wording['card_address'][language]}</p>
                <p className='mt-5 pre-line'>{Address}</p>
            </div></a>}
            {(Phone || Email) &&
            <div className='mt-20'>
                <p className='strong '>{wording['card_contacts'][language]}</p>
                <p className='mt-5 pre-line'>{Phone}</p>
                <p className='mt-5 psre-line'>{Email}</p>
            </div>}
            {SocialNetworks && socialNetworksBlock.length &&
            <div className='mt-20'>
                <p className='strong '>{wording['card_social'][language]}</p>
                <div className='mt-10 pre-line'>
                    {socialNetworksBlock.map((item, index) => {
                        const {name, url, ico} = item
                        return <div key={index} className='d-flex-ac mb-10'>
                            <div className='mt-5 mr-5'>{getIco(ico)}</div>
                            <a href={url} target='_blank' rel='noopener noreferrer' className='mr-5'>{name}</a>
                        </div>
                    })}
                </div>
            </div>}
            <div>
                <div className={'separator mt-10 mb-10'} />
                <HBox gap={5} className='mt-20 pre-line'>
                    <FiCopy className='fc-url' size={ICO_SIZE} />
                    <div className={'fc-url enabledClick fs-14'} onClick={copyToClipboard}>{wording['link-to-business'][language]}</div>
                </HBox>
            </div>
                {<div className='mt-20'>
                    {/*<p className='strong '>{wording['card_social'][language]}</p>*/}
                    {<p className='strong '>{wording['feedback-heading'][language]}</p>}
                    <div className='mt-10 pre-line'>
                        <HBox gap={20} width={'100%'}>
                            <HBox gap={5}>
                                <FiHeart color={isInteraction ? '#ED1C24' : '#000'} />
                                <p>{likesCount}</p>
                                <button onClick={() => addInteractionHandler()}>{wording['feedback-heading-like'][language]}</button>
                            </HBox>
                            <HBox gap={5}>
                                <FiMessageCircle />
                                <p>{messagesCount}</p>
                                <button onClick={() => setCommentMode(!commentMode)}><HBox gap={5}>{wording['feedback-heading-comment'][language]} {commentMode ? <FiX size={14} /> : <FiChevronRight size={14} />}</HBox></button>
                            </HBox>
                        </HBox>
                    </div>
                    {commentMode && <div className='mt-10 pre-line wp-100'>
                        {!token && <LoginScreenEmail />}
                        {token && <VBox width={'100%'} gap={10}>
                            <textarea value={comment} onChange={e => setComment(e.target.value)} maxLength={300} style={{height: 80, width: '100%'}} />
                            <button onClick={() => addCommentHandler()} disabled={comment.length < 5}>{wording['feedback-heading-send'][language]}</button>
                        </VBox>}
                    </div>}
                    <div className='mt-10 separator mb-10 wp-100' />
                    {messagesCount > 0 && <div className='mt-10 pre-line wp-100'>
                        { (messages || []).map((message, messageIndex) => <HBox className={'mb-10'} key={messageIndex} width={'100%'} gap={10} align={'flex-start'}>
                            <FiMessageCircle size={14} color={'#888888'} />
                            <VBox gap={5}>
                                <p>{message.text || ''}</p>
                                <p className={'fs-9'}>{dateToFullStringDate(message.timestamp, true, false, language)}</p>
                            </VBox>
                        </HBox>)}
                    </div>}
                </div>}
            </Linkify>
        </div>
    );
}

const mapStateToProps = ({ language: { language, wording }, user: { token }}) => {
    return { language, wording, token };
};

export default connect(mapStateToProps)(Organization);

