import React, {useEffect, useState} from "react";
import InputLabeled from "../ui/controls/input-labeled";
import {isEmail} from "../../utils/validators";
import VBox from "../ui/layouts/vbox";
import APIService from "../../services/api-service/api-service";
import AccountDetails from "./account-details";
import {FiChevronLeft} from "react-icons/fi";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";

const apiService = new APIService();

const AccountScreen = ({wording, language}) =>  {

    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const [passRequested, setPassRequested] = useState(false)
    const [accountVisible, setAccountVisible] = useState(false)
    const [accountData, setAccountData] = useState([])
    const [categories, setCategories] = useState([])

    const history = useHistory()

    const requestCategories = async () => {
        const result = await apiService.getCategories();
        if (result) {
            setCategories(result)
        }
    }

    useEffect(() => {
        requestCategories()
    }, [])

    const loginHandler = async () => {
        const result = await apiService.validatePin(login, pass);
        if (result) {
            setAccountData(result)
            setPassRequested(false)
            setAccountVisible(true)
        } else {
            setPassRequested(true)
        }
    }

    const updateRequestedHandler = async (id, organization) => {
        const result = await apiService.updateOrganizationDetails(id, login, pass, organization);
        console.log(result)
        if (result) {
            window.alert(wording['account-save-ok'][language])
            //setAccountData(result)
            //setPassRequested(false)
            //setAccountVisible(true)
        } else {
            window.alert(wording['account-save-error'][language])
            //setPassRequested(true)
        }
    }

    const requestPassword = async () => {
        if (!isEmail(login)) {
            window.alert(wording['account-email-error'][language]);
            return;
        }
        const result = await apiService.requestPin(login);
        if (result) {
            setPassRequested(true)
            window.alert(wording['account-email-sent'][language]);
        } else {
            window.alert(wording['account-email-sent-error'][language]);
            setPassRequested(false)
        }
    }

    return (
        <div className='p-20'>
            <div className='p-10 d-flex-wrap-sb-ac'>
                <div className='d-flex-ac enabledClick' style={{gap: 5}} onClick={() => history.push('/')}>
                    <FiChevronLeft size={24} />
                    <p>Home page</p>
                </div>
            </div>
            <div className='separator mt-20 mb-20' />
            <VBox full>
                {!accountVisible && <h2>{wording['account-button'][language]}</h2>}
                {accountVisible && <h2>{wording['account-edit'][language]}</h2>}
                {!passRequested && !accountVisible &&
                    <div className='mt-10'>
                        <span className='mb-20'>{wording['account-email'][language]}</span>
                        <InputLabeled className='wp-30 mw-280 mb-20 mt-20' initValidation type='text' value={login}
                                      onChange={(val) => setLogin(val)} mandatory submit={loginHandler}/>
                        <button className="button-main mt-10"
                                onClick={requestPassword}>{wording['account-button-email'][language]}</button>
                    </div>
                }

                {passRequested && !accountVisible && <div>
                    <span className='mb-10'>{wording['account-pin-heading'][language]}</span>
                    <InputLabeled containerClassName='w-275 widthMobile mb-20 mt-20' initValidation type='text' value={pass} onChange={(val) => setPass(val)} mandatory submit={loginHandler} />
                    <button className="button-main mt-20" onClick={loginHandler}>{wording['account-button-pin'][language]}</button>
                    <p className='mt-50 enabledClick' onClick={() => setPassRequested(false)}><u>{wording['account-button-back'][language]}</u></p>
                </div>}

                {accountVisible &&
                    <AccountDetails categories={categories} data={accountData} onUpdate={(id, organization) => updateRequestedHandler(id, organization)} />
                }

            </VBox>
            <div className="mt-30"/>
        </div>
    );
}


const mapStateToProps = ({ language: { language, wording }}) => {
    return { language, wording };
};


export default connect(mapStateToProps)(AccountScreen);
